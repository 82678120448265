import React, { useState, useCallback, useRef, useImperativeHandle, forwardRef } from "react";
import type {Node} from 'react';

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";
import {dGlobal} from "functions/DGlobal";

import {
  Button,
  CardHeader,
  CardBody,
  Collapse,
  FormGroup,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalFooter
} from "reactstrap";

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  
} from "reactstrap";


const ModalLogin = (props, ref): Node => {
  
  const [modalOpen, setModalOpen] = useState(false);
  const refModal =  useRef();
  
    
  const buttonStyle = {
      display: 'block',
      margin: '10px auto'
    };  
    
  React.useEffect(() => {
      
    console.log("ModalDownload mounted", props);
    
    return () => {
        console.log('ModalDownload  destroy');
    }

  }, []);
  
  useImperativeHandle(ref, () => ({
    // methods connected to `ref`
    open: (data) => { open(data) },
    close: () => { close() }
  }));
  
  const open = (data) => {
      
    setModalOpen(true);
  
  };
  
  const close = () => {
    setModalOpen(false);
  };
  
  const doLogin = () => {
      
    let formData = new FormData()

    //You could add a key/value pair to this using #FormData.append:

    formData.append('email', 'yolis@promo.com.mx');
    formData.append('password', '12345678');

    fetch("https://api.promo.com.mx/api/admin/login", {
	method: "POST" ,
	timeoutInterval: 15000, // milliseconds
	body: formData,
        headers: {
		Accept: "application/json; charset=utf-8", "Access-Control-Allow-Origin": "*", "e_platform": "mobile",
	}
    })  
    .then(response => response.json())
    .then(responseJson => {
        console.log('responseJson', responseJson);

        if(!responseJson.error){
            dGlobal.setStorage('user_token', responseJson.token);
        }
        else {
            
        }
        
    })
    .catch(error => {
        console.error(error);
    });
   
  };
  
  return (
    <>
      <Modal
                isOpen={modalOpen}
                style={{
                    maxWidth: 500,
                    maxHeight: 280,
                    
                  }}
                  
                ref={refModal}  
                
                centered={true}
              >
                <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setModalOpen(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="title title-up">{props.title}</h4>
                </div>
                <div className="modal-body">
                  
                
                </div>
                <ModalFooter>
                 
                </ModalFooter>
              </Modal>
              
    </>
  );
}

export default forwardRef(ModalLogin);
