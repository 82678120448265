import React, { useState, useCallback, useRef, useImperativeHandle, forwardRef } from "react";
import type {Node} from 'react';

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

import {
  Button,
  CardHeader,
  CardBody,
  Collapse,
  FormGroup,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalFooter
} from "reactstrap";

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  
} from "reactstrap";


// core components
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

//import pdfFile from 'assets/pdf/catalogo_1.pdf' 

import { useWindowHeight, useWindowWidth } from '@wojtekmaj/react-hooks';
import screenfull from 'screenfull';

function getStorageValue(key, defaultValue) {
  // getting stored value
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
  
}
  
const ModalCatalogo = (props, ref): Node => {
  
  let screenHeight = useWindowHeight();  
  let screenWidth = useWindowWidth();
  
  let _initPageItems = 1;
  let _endPageItems = 1;
  
  const modalHeaderHeight = 90;
  const modalPadding = 48;
  
  let navPageItemWidth = 46;
  let navTotPageItemsVisible = 1;

  
  const [file, setFile] = useState(props.file);
  const [modalOpen, setModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(props.numpages);
  const [pageNumber, setPageNumber] = useState(1);
  const [navPageItems, setNavPageItems] = useState([]);
  
  const [pageHeight, setPageHeight] = useState(100);
  const [pageWidth, setPageWidth] = useState(100);
  
  const [modalHeight, setModalHeight] = useState(100);
  const [modalWidth, setModalWidth] = useState(100);
  
  const refModal =  useRef();
  
  const updateNavPageItems = (init) => {
      console.log('updateNavPageItems', init);
      _initPageItems = init;
      let index = _initPageItems - 1;
      let nPageItems = [];
      
      for (let i = 0; i < navTotPageItemsVisible; i++) {
        
        index++;
        nPageItems.push(index);
        
        if(index >= numPages) break;  
      }
      
      _endPageItems = index;
      
      setNavPageItems(nPageItems);
      
      console.log('navPageItems', navPageItems, nPageItems);
      
  };
  
  const updateComponent = () => {
        
    let screenHeight = window.innerHeight;  
    let screenWidth = window.innerWidth;
    
    let scaleratio = 1;

    let mWidth = screenWidth * .95
    let mHeight = screenHeight * .95;
  
  if(props.pdfheight >= props.pdfwidth) {
      scaleratio = ((mHeight - modalHeaderHeight - modalPadding) / props.pdfheight);
      mWidth = (props.pdfwidth * scaleratio) + modalPadding;
      
      if(mWidth > screenWidth){
          
          mWidth = screenWidth * .95;
          
          scaleratio = ((mWidth  - modalPadding) / props.pdfwidth);
          mHeight = (props.pdfheight * scaleratio) + modalHeaderHeight + modalPadding;
          
      }
      
  }
  else {
      scaleratio = ((mWidth  - modalPadding) / props.pdfwidth);
      mHeight = (props.pdfheight * scaleratio) + modalHeaderHeight + modalPadding;
      
      if(mHeight > screenHeight){
          
          mHeight = screenHeight * .95;
          
          scaleratio = ((mHeight - modalHeaderHeight - modalPadding) / props.pdfheight);
          mWidth = (props.pdfwidth * scaleratio) + modalPadding;
      }
      
  } 
  
  setModalHeight(mHeight);
  setModalWidth(mWidth);
  
  console.log('mHeight', mHeight);
  
  let pHeight = props.pdfheight * scaleratio;
  let pWidth = props.pdfwidth * scaleratio;
  
  setPageHeight(pHeight);
  setPageWidth(pWidth);
  
  console.log('pWidth / navPageItemWidth', pWidth / navPageItemWidth, pWidth , navPageItemWidth);
  navTotPageItemsVisible = Math.floor(pWidth / navPageItemWidth) - 6;
  
  if(navTotPageItemsVisible < 1) navTotPageItemsVisible = 3;
  
  console.log('navTotPageItemsVisible', navTotPageItemsVisible);
  
  updateNavPageItems(1);

  }
  
  React.useEffect(() => {
    
    function handleResize() {
        updateComponent();
    }

    window.addEventListener('resize', handleResize)
    
    return () => {
        console.log('ModalCatalogo  destroy');
        window.removeEventListener('resize', handleResize)
    }

  }, []);
  
  useImperativeHandle(ref, () => ({
    // methods connected to `ref`
    open: () => { open() },
    close: () => { close() }
  }));
  
  const open = () => {
      
    const GFullScreenCat = getStorageValue('GFullScreenCat', true);  
    
    console.log('GFullScreenCat', GFullScreenCat);
    
    if(GFullScreenCat && screenfull.isEnabled) screenfull.request();
    setModalOpen(true);
    
    updateComponent(); 
  };
  
  
  const close = () => {
    setModalOpen(false);
  };
  
  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  }
  
  const goPageNumber = useCallback((page) => {
    setPageNumber(page);
    console.log('goPageNumber', page);
    
  }, []);
  
  const goPageInit = useCallback(() => {
      
    setPageNumber(1);
    updateNavPageItems(1);
    console.log('goPageInit');
    
  }, []);
  
  const goPageEnd = useCallback(() => {
      
    console.log('goPageEnd', numPages, navTotPageItemsVisible);
    
    setPageNumber(numPages);
    
    let navInit = (Math.floor((numPages / navTotPageItemsVisible)) * navTotPageItemsVisible) + 1;
    
    updateNavPageItems(navInit);
    
    
  }, []);
  
  const changePage = useCallback((offset) => {
    goPageNumber((prevPageNumber) => {
        let page = (prevPageNumber || 1) + offset;  
        
        if(page > _endPageItems){
            updateNavPageItems(page);
        }
        else if(page < _initPageItems){
            updateNavPageItems(page - navTotPageItemsVisible + 1);
        }
        
        console.log('changePage go', page, _endPageItems, _initPageItems, navTotPageItemsVisible);
        return page;
    });
    
    console.log('changePage', pageNumber);  
      
  }, []);
  
  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);
  
  return (
    <>
      <Modal
                isOpen={modalOpen}
                style={{
                    maxWidth: modalWidth,
                    width: modalWidth,
                    maxHeight: modalHeight,
                    height: modalHeight
                  }}
                  
                ref={refModal}  
                
              >
                <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setModalOpen(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="title title-up">{props.title}</h4>
                </div>
                <div className="modal-body">
                  <div>
                  <div >
                <span>
                  {`Página ${pageNumber || (numPages ? 1 : '--')} de ${numPages || '--'}`}
                </span>
              </div>
              <div className="pagination-container justify-content-center">
                <Pagination
                  className="pagination pagination-info"
                  listClassName="pagination-info"
                >
                  <PaginationItem className="arrow-margin-left-init-modal">
                    <PaginationLink
                      aria-label="Anterior"
                      onClick={() => goPageInit()}
                  
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="fa fa-angle-double-left"
                        ></i>
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                  
                  <PaginationItem className="arrow-margin-left-modal">
                    <PaginationLink
                      aria-label="Anterior"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                  
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="fa fa-angle-left"
                        ></i>
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                  
                  {
                  navPageItems.map(function(index, i){
                    return ( 
                      <PaginationItem className={`${pageNumber == index ? "active" : ""}`}>
                        <PaginationLink onClick={() => setPageNumber(index)}>
                        {index}
                        </PaginationLink>
                      </PaginationItem> );
                  })}

                  <PaginationItem className="arrow-margin-right-modal">
                    <PaginationLink
                      aria-label="Siguiente"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="fa fa-angle-right"
                        ></i>
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                  
                  <PaginationItem className="arrow-margin-right-end-modal">
                    <PaginationLink
                      aria-label="Ultima"
                      onClick={() => goPageEnd()}
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="fa fa-angle-double-right"
                        ></i>
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} >
        <Page pageNumber={pageNumber} height={pageHeight} />
      </Document>
      
      
    </div>
    
    
                </div>
                <ModalFooter>
                 
                </ModalFooter>
              </Modal>
              
    </>
  );
}

export default forwardRef(ModalCatalogo);
