import React from "react";

import BasePageView from "views/BasePageView.js";

import Header from "components/Header.js";

function EditOfertasView() {
  
  return (
      <BasePageView>        
      
      </BasePageView>
  );
}

export default EditOfertasView;
