/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  NavLink,
  Container,
  Row,
  Col
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";

import {dGlobal} from "functions/DGlobal";

// core components

function Footers() {
    
  const navigate = useNavigate();
  
  return (
    <>
      <div className="section section-footers" data-background-color="gray">
        <Container>
          <div className="title">
            
          </div>
        </Container>
        <div id="footer-areas">
          
          
          <br></br>
          <br></br>
          <footer className="footer footer-big" data-background-color="black">
            <Container>
              <div className="content">
                <Row>
                  {dGlobal.isDevelopment && 
                    <>
                  <Col md="2">
                    <h5>Título Menú 1</h5>
                    <ul className="links-vertical">
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 1
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 2
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 3
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 4
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col md="2">
                    <h5>Título Menú 2</h5>
                    <ul className="links-vertical">
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 1
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 2
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 3
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 4
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 5
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          href="#promo"
                          onClick={e => e.preventDefault()}
                        >
                          Opción 4
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col md="4">
                    <h5>Título Menú 3</h5>
                    <div className="social-feed">
                      <div className="feed-line">
                        <i className="fab fa-twitter"></i>
                        <p>
                          Algún artículo informativo para clientes 1.
                        </p>
                      </div>
                      <div className="feed-line">
                        <i className="fab fa-twitter"></i>
                        <p>
                          Algún artículo informativo para clientes 2.
                        </p>
                      </div>
                      <div className="feed-line">
                        <i className="fab fa-facebook-square"></i>
                        <p>
                          Algún artículo informativo para clientes 3.
                        </p>
                      </div>
                    </div>
                  </Col>
                  
                    </>
                  }
                  
                  <Col md="4">
                    <h5>Siguenos</h5>
                    <ul className="social-buttons">
                      <li>
                        <Button
                          className="btn-icon btn-neutral btn-round mr-1"
                          color="twitter"
                          href="https://twitter.com/promo_articulo?s=21"
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="btn-icon btn-neutral btn-round mr-1"
                          color="facebook"
                          href="https://www.facebook.com/Promo.Ofertas"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-square"></i>
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="btn-icon btn-neutral btn-round"
                          color="instagram"
                          href="https://instagram.com/promo_articulos?utm_medium=copy_link"
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </Button>
                      </li>
                    </ul>
                    <h5>
                      <small>Algunos números</small>
                    </h5>
                    <h5>
                      14.521 <small className="text-muted">Artículos Promocionales</small>
                    </h5>
                    <h5>
                      1.423.183{" "}
                      <small className="text-muted">Ventas</small>
                    </h5>
                  </Col>
                </Row>
              </div>
              
              <hr></hr>
              <ul className="pull-left">
                <li>
                  <a onClick={() => {navigate("/"); window.scrollTo(0, 0)}} >
                    Inicio
                  </a>
                </li>
                <li>
                  <a onClick={() => {navigate("/nosotros"); window.scrollTo(0, 0)}}>
                    Nosotros
                  </a>
                </li>
                <li>
                  <a onClick={() => {navigate("/productos"); window.scrollTo(0, 0)}}>
                    Productos y Servicios
                  </a>
                </li>
                <li>
                  <a onClick={() => {navigate("/catalogo"); window.scrollTo(0, 0)}}>
                    Catálogo
                  </a>
                </li>
                <li>
                  <a onClick={() => {navigate("/contacto"); window.scrollTo(0, 0)}}>
                    Contácto
                  </a>
                </li>
              </ul>
              <div className="copyright pull-right">
                Copyright © {new Date().getFullYear()} Derechos Reservados Promo.com.mx
              </div>
            </Container>
          </footer>
          
        </div>
      </div>
    </>
  );
}

export default Footers;
