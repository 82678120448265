import React, { useState, useCallback, useRef } from "react";

// reactstrap components
import { Card, Container, Row, Col, Button } from "reactstrap";
import Switch from "react-bootstrap-switch";


// core components
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { useLocalStorage } from "components/useLocalStorage";
import ModalCatalogo from "components/ModalCatalogo";
import ModalDownload from "components/ModalDownload";

import fullScreenIcon from 'assets/img/fullscreen_icon.svg';

function Features() {
  
  const refModalCatalogo_1 =  useRef();
  const refModalCatalogo_2 =  useRef();
  const refModalCatalogo_3 =  useRef();
  const refModalCatalogo_4 =  useRef();
  const refModalDownload = useRef();
  
  const [GFullScreenCat, setGFullScreenCat] = useLocalStorage("GFullScreenCat", true);
  
  const onCatalogo_Click = (index) => {
    console.log('onCatalogo_Click', index, refModalCatalogo_1);
    
    refModalCatalogo_1.current.open();
     
  };
  
  return (
    <>
      <div className="cd-section" id="features">

        <div className="features-4">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Catálogo</h2>
                <h4 className="description">
                  Contamos con un amplia variedad de artículos promocionales que podrá elegir de nuestros 4 principales importadores, haga clic sobre la imagen del importador, navege y elijga los artículos que mas representan a su marca.
                  <br/>
                  También puede descargar los catalogos completos haciendo clic en el botón de descarga dentro del área de visualización.
                </h4>
              </Col>
            </Row>
            <Row className="justify-content-center" style={{alignItems: "center"}}>
              <h6 style={{paddingRight: 8}}>visualizar en ventana completa</h6>
                <Switch animate={true} bsSize="normal" defaultValue={GFullScreenCat} offColor="default" onColor="primary" offText="NO" onText="SI" onChange={(el, state) => setGFullScreenCat(state)}></Switch>
              <div className="icon icon-info">
                    <img src={fullScreenIcon} height="32px" />
                  </div>
            </Row>
            <Row>
              <Col md="3">
                <Card
                  className="card-background-catalogo card-raised-catalogo"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/bg50.png") + ")"
                  }}
                  onClick={() => refModalCatalogo_1.current.open()}
                >
                </Card>
                <Row className="justify-content-center">
              <Button className="btn-round btn-catalogo" color="info" outline type="button" onClick={() => refModalCatalogo_1.current.open()}>
                <i className="now-ui-icons ui-1_zoom-bold icon-pad-right"></i>
                Visualizar
              </Button>
              <Button className="btn-round btn-catalogo" color="info" type="button" onClick={() => refModalDownload.current.open({url:'pdf/catalogo_1.pdf', name:'catalogo_1.pdf'})}>
                <i className="now-ui-icons arrows-1_cloud-download-93 icon-pad-right"></i>
                Descargar
              </Button>
               </Row>
              </Col>
              <Col md="3">
                <Card
                  className="card-background-catalogo card-raised-catalogo"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/bg51.png") + ")"
                  }}
                  onClick={() => refModalCatalogo_2.current.open()}
                >
                </Card>
                <Row className="justify-content-center">
              <Button className="btn-round btn-catalogo" color="info" outline type="button" onClick={() => refModalCatalogo_2.current.open()}>
                <i className="now-ui-icons ui-1_zoom-bold icon-pad-right"></i>
                Visualizar
              </Button>
              <Button className="btn-round btn-catalogo" color="info" type="button" onClick={() => refModalDownload.current.open({url:'pdf/catalogo_2.pdf', name:'catalogo_2.pdf'})}>
                <i className="now-ui-icons arrows-1_cloud-download-93 icon-pad-right"></i>
                Descargar
              </Button>
               </Row>
              </Col>
              <Col md="3">
                <Card
                  className="card-background-catalogo card-raised-catalogo"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/bg52.png") + ")"
                  }}
                  onClick={() => refModalCatalogo_3.current.open()}
                >
                </Card>
                <Row className="justify-content-center">
              <Button className="btn-round btn-catalogo" color="info" outline type="button" onClick={() => refModalCatalogo_3.current.open()}>
                <i className="now-ui-icons ui-1_zoom-bold icon-pad-right"></i>
                Visualizar
              </Button>
              <Button className="btn-round btn-catalogo" color="info" type="button" onClick={() => refModalDownload.current.open({url:'pdf/catalogo_3.pdf', name:'catalogo_3.pdf'})}>
                <i className="now-ui-icons arrows-1_cloud-download-93 icon-pad-right"></i>
                Descargar
              </Button>
               </Row>
              </Col>
              <Col md="3">
                <Card
                  className="card-background-catalogo card-raised-catalogo"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/bg53.png") + ")"
                  }}
                  onClick={() => refModalCatalogo_4.current.open()}
                >
                </Card>
                <Row className="justify-content-center">
              <Button className="btn-round btn-catalogo" color="info" outline type="button" onClick={() => refModalCatalogo_4.current.open()}>
                <i className="now-ui-icons ui-1_zoom-bold icon-pad-right"></i>
                Visualizar
              </Button>
              <Button className="btn-round btn-catalogo" color="info" type="button" onClick={() => {refModalDownload.current.open({url:'pdf/catalogo_4.pdf', name:'catalogo_4.pdf'})}}>
                <i className="now-ui-icons arrows-1_cloud-download-93 icon-pad-right"></i>
                Descargar
              </Button>
               </Row>
              </Col>
            </Row>
          </Container>
        </div>
        
        <ModalCatalogo id="c1" title="Catálogo 4 Promo" file="pdf/catalogo_1.pdf" numpages={59} pdfwidth={808.44094488} pdfheight={808.44094488} ref={refModalCatalogo_1} />
        <ModalCatalogo id="c2" title="Catálogo Promo Opción" file="pdf/catalogo_2.pdf" numpages={646} pdfwidth={1096.0629921} pdfheight={869.29133858} ref={refModalCatalogo_2} />
        <ModalCatalogo id="c3" title="Catálogo Doble Vela" file="pdf/catalogo_3.pdf" numpages={250} pdfwidth={831.49606299} pdfheight={1058.2677165} ref={refModalCatalogo_3} />
        <ModalCatalogo id="c4" title="Catálogo Macma" file="pdf/catalogo_4.pdf" numpages={118} pdfwidth={1632} pdfheight={1056} ref={refModalCatalogo_4} />
        
        <ModalDownload id="c4" title="Descargando.." ref={refModalDownload} />
        
      </div>
      
    </>
  );
}

export default Features;
