/*eslint-disable*/
import React, { useState, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";

import { useWindowHeight, useWindowWidth } from '@wojtekmaj/react-hooks';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import {dGlobal} from "functions/DGlobal";
import ModalLogin from "components/ModalLogin";
import logo from '../../assets/img/logo.svg' ;

function PromoNavbar() {
  const screenWidth = useWindowWidth();
  
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarOpen1, setNavbarOpen1] = React.useState(false);
  
  const [isMobile, setIsMobile] = React.useState(screenWidth <= 478);
  
  const refModalLogin =  useRef();
  
  const navigate = useNavigate();
  
  const goAdmin = () => {
      if(!dGlobal.getStorage('user_token', false)){
        refModalLogin.current.open();
      }
      else {
        navigate("/admin");  
      }
  };
  
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="bg-white fixed-top" expand="lg" >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <img src={logo} height="120px" />
            </NavbarBrand>
          </div>
          <BrowserView>
            <img src={require("assets/img/underconstruction.png")} height="100px" />
          </BrowserView>
          
          {dGlobal.isDevelopment && 
            <>
            <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
            </>
          }
                
      
        </Container>
        <Container className="navbar-container">  
          
         <Container>
          <div className="navbar-translate">
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
              
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="mx-auto" navbar>
                  <NavItem>
                    <NavLink onClick={() => navigate("/")}>
                      Inicio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => navigate("/nosotros")} >
                      Nosotros
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => navigate("/productos")} >
                      Productos y Servicios
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => navigate("/catalogo")} >
                      Catálogo
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => navigate("/contacto")}>
                      Contacto
                    </NavLink>
                  </NavItem>
                </Nav>
           
            <Nav className="nav" navbar>
                  <NavItem>
                    <NavLink
                      href="https://twitter.com/promo_articulo?s=21"
                      target="_blank"
                    >
                      <i className="fab fa-twitter"></i>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.facebook.com/Promo.Ofertas"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://instagram.com/promo_articulos?utm_medium=copy_link"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </NavLink>
                  </NavItem>
                </Nav>
                
                <Nav className="nav" navbar>
                  <NavItem>
                    <NavLink onClick={() => goAdmin()}>
                      <i className="now-ui-icons users_circle-08"></i>
                    </NavLink>
                  </NavItem>
                </Nav>
          </Collapse>
         
        </Container>
        </Container>
      </Navbar>
      <ModalLogin id="c4" title="Iniciando Sesión..." ref={refModalLogin} />
    </>
  );
}

export default PromoNavbar;
