import React from "react";

// reactstrap components

// core components
import PromoNavbar from "components/Navbars/PromoNavbar.js";
import Footer from "components/Footer.js";
import Header from "components/Header.js";


import WhatsAppWidget from 'react-whatsapp-widget'
import 'assets/css/whatsappbtn.css'

import CookieConsent from "react-cookie-consent";


import {dGlobal} from "functions/DGlobal";
import iconLogo from '../assets/img/iconLogo.svg' ;

const IconLogo = () => <img src={iconLogo} height="24px" />;

function BasePageView(props) {
  React.useEffect(() => {
    document.body.classList.add("sections-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    return function cleanup() {
      document.body.classList.remove("sections-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      
      <div className="header-margen"></div>
      {props.children}
      <Footer />
      <CookieConsent debug={dGlobal.isDevelopment} disableStyles={true} buttonText="Acepto el uso de cookies" location="bottom" buttonClasses="btn btn-primary" containerClasses="container-cookies-consent" contentClasses="content-cookies-consent" buttonWrapperClasses="button-cookies-consent" >
        Esta web utiliza cookies propias para analizar y mejorar su experiencia de navegación. {" "}
      </CookieConsent>

      <div className="whastapp-btn">
      <WhatsAppWidget IconLogo={IconLogo} phoneNumber='+523323674059' message='Hola!, 👋🏼 ¿podemos ayudarte?' companyName="Promo" placeholder="Escribe tu mensaje" sendButton="Enviar" textReplyTime="Respondemos en el mismo día" />
      <IconLogo />
      </div>
      
    </> 
  );
}

export default BasePageView;
