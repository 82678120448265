import {isMobile} from 'react-device-detect';

class DGlobal {

    constructor() {
        this.appState = 'init';
        this.started = false;
        this.count = 0;
        this.windowsModals = new Map();
        
        this.isDevelopment = (process.env.REACT_APP_ENV === 'devel');
        this.isMobile = isMobile;
        
        console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);
    }
    
    init = () => {
        
    }
    
    getStorage(key, defaultValue) {
        // getting stored value
        if (typeof window !== "undefined") {
            const saved = localStorage.getItem(key);
            const initial = saved !== null ? JSON.parse(saved) : defaultValue;
            return initial;
        }
  
    }
    
    setStorage(key, value){
        localStorage.setItem(key, JSON.stringify(value));
    }
    
    openWindowModal(id){
        this.windowsModals.get(id).current.open();
    }
    
    closeWindowModal(id){
        this.windowsModals.get(id).current.close();
    }
}

export let dGlobal = new DGlobal();
