import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages
import AboutUs from "views/examples/AboutUs.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/examples/ContactUs.js";
import Ecommerce from "views/examples/Ecommerce.js";
import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/examples/LoginPage.js";
import NucleoIcons from "views/NucleoIcons.js";
import Presentation from "views/Presentation.js";
import Pricing from "views/examples/Pricing.js";
import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Sections from "views/Sections.js";
import SignupPage from "views/examples/SignupPage.js";
// others

import PromoView from "views/PromoView.js";
import NosotrosView from "views/NosotrosView.js";
import ProductosView from "views/ProductosView.js";
import CatalogoView from "views/CatalogoView.js";
import ContactoView from "views/ContactoView.js";
import AdminView from "views/AdminView.js";

import PromoNavbar from "components/Navbars/PromoNavbar.js";

ReactDOM.render(
  <BrowserRouter>
    <PromoNavbar />  
    <Routes>
      
      <Route path="/about-us" element={<AboutUs  />} />
      <Route path="/blog-post" element={<BlogPost  />} />
      <Route path="/blog-posts" element={<BlogPosts  />} />
      <Route path="/contact-us" element={<ContactUs  />} />
      
      <Route path="/e-commerce" element={<Ecommerce  />} />
      <Route path="/indexold" element={<Index  />} />
      <Route
        path="/landing-page"
        element={<LandingPage  />}
      />
      <Route path="/login-page" element={<LoginPage  />} />
      <Route
        path="/nucleo-icons"
        element={<NucleoIcons  />}
      />
      <Route
        path="/presentation"
        element={<Presentation  />}
      />
      <Route path="/pricing" element={<Pricing  />} />
      <Route
        path="/product-page"
        element={<ProductPage  />}
      />
      <Route
        path="/profile-page"
        element={<ProfilePage  />}
      />
      <Route path="/sections" element={<Sections  />} />
      <Route path="/sign-up" element={<SignupPage  />} />
      
      <Route path="/" element={<PromoView />} />  
      <Route path="/nosotros" element={<NosotrosView  />} />
      <Route path="/productos" element={<ProductosView  />} />
      <Route path="/catalogo" element={<CatalogoView  />} />
      <Route path="/contacto" element={<ContactoView  />} />
      <Route path="/admin" element={<AdminView  />} />  
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
