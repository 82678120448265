import React from "react";

import {dGlobal} from "functions/DGlobal";

import BasePageView from "views/BasePageView.js";

import Header from "components/Header.js";
import Projects from "components/Projects.js";
import Features from "components/Features.js";

import Pricing from "./sections-sections/Pricing.js";
import Testimonials from "./sections-sections/Testimonials.js";


function PromoView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Header />
        
        {dGlobal.isDevelopment && <>
        
        <Features />
        <Projects /></>
        }
        
        {/*<Pricing />*/}
        {/*<Testimonials />*/}
      </div>
      </BasePageView>
  );
}

export default PromoView;
